<script setup lang="ts">
import { storeToRefs } from 'pinia'

import CurrencySymbol from '~/components/shared/currency/symbol.vue'

import { useMiscCurrencyStore } from '~/stores/miscCurrency'

import type { Currency } from '~/types/currency'

type Props = {
  targetCurrency?: Currency | ''
}
type Slots = {
  default(): any
}

const props = withDefaults(defineProps<Props>(), {
  targetCurrency: '',
})
defineSlots<Slots>()

const invertedCurrencies = ['EUR'] as Currency[]
const { targetCurrency: TARGET_CURRENCY } = storeToRefs(useMiscCurrencyStore())

const currency = computed<Currency>(
  () => props.targetCurrency || TARGET_CURRENCY.value,
)
const invert = computed<boolean>(() =>
  invertedCurrencies.includes(currency.value),
)
</script>

<!-- Yes, the absence of space is indeed intentional, don't @ me -->
<template>
  <div class="tw-inline-flex tw-items-center tw-justify-center">
    <CurrencySymbol v-if="!invert" /><slot /><CurrencySymbol v-if="invert" />
  </div>
</template>
