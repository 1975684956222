<script setup lang="ts">
import { storeToRefs } from 'pinia'

import { useMiscCurrencyStore } from '~/stores/miscCurrency'

import { symbolLookupTable } from '~/types/currency'

import type { Currency } from '~/types/currency'

type Props = {
  targetCurrency?: Currency | ''
}

const props = withDefaults(defineProps<Props>(), {
  targetCurrency: '',
})

const { targetCurrency: TARGET_CURRENCY } = storeToRefs(useMiscCurrencyStore())

const targetKey = computed<Currency>(
  () => props.targetCurrency || TARGET_CURRENCY.value,
)
const symbol = computed<string>(() => symbolLookupTable[targetKey.value])
</script>

<template>
  <span class="currencySymbolWrapper tw-inline-block">
    {{ symbol }}
  </span>
</template>

<style scoped lang="scss">
.currencySymbolWrapper {
  text-decoration-line: inherit;
}
</style>
